@import "./variables.scss";

.input_title {
  font-size: 18px;
  font-weight: 600;
  color: $core-black;
  opacity: 1;
}

.input_description {
  font-size: 12px;
  color: $neutral-gray;
  opacity: 1;
}

.view_property {
  background: #F2F4F8 0 0 no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  vertical-align: middle;
  color: $neutral-gray;
}

.textarea_wide {
  width: 100%;
  border: 1px solid $bg-light-2;
  border-radius: 5px;
}
.textarea_wide:focus {
  outline: 1px solid $dark;
  box-shadow: none;
}

.input_wide {
  width: 100%;
  padding-left: 10px !important;
  height: 38px;
  border: 1px solid $bg-light-2;
  border-radius: 5px;
}
.input_wide:focus {
  outline: 1px solid $dark;
  box-shadow: none;
}

.dropdown_wide {
  width: 100%;
  height: 38px;
  border: 1px solid $bg-light-2;
  color: $dark;
  background-color: transparent;
  border-radius: 5px;
  text-align: left;
}
.dropdown_wide_toggle{
  width: 100%;
  height: 38px;
  color: $dark;
  background-color: transparent;
  border: 0 transparent;
  text-align: left;
}
