@import "./variables.scss";

body {
  background-color: $bg-light-1;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}
h2 {
  margin-top: 50px;
}

.alert_text {
  font-size: 20px;
}
.pages_title {
  text-transform: uppercase;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 40px;
}
.login_title {
  text-transform: uppercase;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
}

.modal-backdrop
{
  opacity:0 !important;
}
