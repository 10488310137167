@import "./variables.scss";

.auth_button {
  border-radius: 30px;
  font-size: 16px;
}
.auth_button_icon {
  font-size: 24px !important;
}

.content_light_button, .content_dark_button {
  border-radius: 22px;
  border: $dark 1px solid;
  width: 106px;
  height: 38px;
  text-decoration: none;
  text-align: center;
  padding: 7px;
}
.content_light_button {
  background-color: $light;
  color: $dark;
}
.content_light_button:hover {
  background-color: $dark;
  color: $light;
}
.content_dark_button {
  background-color: $dark;
  color: $light;
}
.content_dark_button:hover {
  background-color: $light;
  color: $dark;
}

.content_danger_button {
  border-radius: 22px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: $danger;
  color: $light;
  border-color: $danger;
}
.content_danger_button:hover {
  background-color: $light;
  color: $dark;
  border-color: $dark;
}
