@import "./variables.scss";

.nav_header {
  font-size: 30px;
  padding-top: 15px;
  margin-left: 12px;
}

.nav_header_sub {
  font-size: 20px;
  margin-left: 12px;
}

.toolbar_area {
  .accordion-header {
    border: none;
    margin-top: 5px;
  }
  .accordion-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .accordion-item, .accordion-button {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .accordion-button {
    padding: 6px 12px;
    border-bottom: 1px solid white;
    color: $core-green;
    text-transform: uppercase;
    font-size: 16px;
  }
  .accordion-button::after {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/></svg>');
  }
  .accordion-button.collapsed {
    border-bottom: none;
  }
  .accordion-button:focus {
    box-shadow: none;
  }

  a {
    background-color: transparent !important;
    border-color: transparent !important;
    border-bottom: 3px solid transparent !important;
    text-align: left;
    justify-content: left;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: $core-green;
  }
  a.active {
    border-bottom: 3px solid $core-green !important;
  }

  .navigate {
    text-transform: uppercase !important;
  }
}
