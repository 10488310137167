.page_area {
  padding-top: 55px;
  height: 100vh;
  width: 100vw;
}

.toolbar_area {
  flex: 0 0 auto;
  width: 300px;
}

.content_area {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
}
