@import "./variables.scss";

.card_container {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 3px #00000029;
  border: 1px solid $bg-light-1;
  opacity: 1;
  padding: 80px;
}

.card_container_small {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 3px #00000029;
  border: 1px solid $bg-light-1;
  opacity: 1;
  padding: 20px;
  margin: 0 40px;
}

.card {
  width: 18rem;
  height: 120px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 3px #00000029;
  border: 1px solid $bg-light-1;
  opacity: 1;
}

.card_new {
  border: 1px dashed #505050;
  background: transparent 0 0 no-repeat padding-box;
  box-shadow: 0 0 3px #00000029;
  opacity: 1;
  cursor: pointer;
}

.card_new:hover {
  border: 3px dashed var(--§c-background-light-2-dde1e6);
  background: transparent 0 0 no-repeat padding-box;
  box-shadow: 0 0 3px #00000029;
  opacity: 1;
}
.card_icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
.card_title {
  text-transform: uppercase;
  font-size: 1.30rem;
  font-style: italic;
  font-weight: 700;
}
