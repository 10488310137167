@import "./variables.scss";

.documentation {
  .syntaxHighlighterParent {
    position: relative;
  }
  .syntaxHighlighterParent code {
    padding-right: 30px;
  }
  .copyButton {
    position: absolute;
    right: 14px;
    top: 14px;
    fill: black;
    height: 28px;
    width: 28px;
    padding: .2rem;
    background: transparent;
    border: 1px solid darkgray;
    border-radius: 3px;
    font-size: 13.33px;
    line-height: normal;
  }
  .copyButton:hover {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  blockquote{
    font-size: 1.1em;
    margin: 30px auto;
    font-style: italic;
    color: #555555;
    padding: 20px;
    border-left: 4px solid #555555;
    line-height: 1.6;
    position: relative;
    background: $bg-light-1;
  }
  blockquote p {
    margin: 0;
  }
  img {
    max-width: 800px;
  }
  table, th, td {
    border: 1px solid black;
    padding: 5px 10px;
  }

  th, tr:nth-child(even) {background-color: #f2f2f2;}
}
